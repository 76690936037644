import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import authReducer from './authReducer';
import registerReducer from './registerReducer';
import contractReducer from './contractReducer';

export default combineReducers({
  authReducer,
  registerReducer,
  contractReducer,
  form
});

