import {
  ANTIVIRUS_KEY,
  FETCHING_KEY,
  ANTIVIRUS_KEY_DENY
} from '../actions/types'

const INITIAL_STATE = {
  authentication: null,
  fetching: false,
  authError: null,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ANTIVIRUS_KEY:
      return {
        ...state,
        authentication: action.payload,
        fetching: false,
      }
    case FETCHING_KEY:
      return {
        ...state,
        fetching: true,
        authError: INITIAL_STATE.authError,
      }
    case ANTIVIRUS_KEY_DENY:
      return {
        ...state,
        fetching: false,
        authError: action.payload,
      }
    default:
      return state;
    }
}
