import React, { Component } from 'react';
import { Image, Row, Col, Button } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Footer from '../components/Footer';

class Antivirus extends Component {
  getSoftwareKey = (contracts) => {
    const keys = contracts.map(contract => {
      return contract.SOFTWARES.find(item =>
        item.DS_SOFTWARE === 'McAfee Multi Device 90 Trial (MMA)'
      );
    });
    return keys;
  }

  renderButtons = (softKeys) => {
    return softKeys.map((item) =>
      <Button key={item.ID_SOFTWARE_LICENCA} href={item.URL} target="_blank" className='download-button' disabled={item.URL ? false : true}>
        {item.URL ? <span>BAIXAR ANTIVÍRUS</span> : <span>CHAVE EXPIRADA</span>}
      </Button>
    );
  }

  render () {
    const { authentication, fetching } = this.props;

    if (fetching) {
      return <div>loading...</div>
    } else if (!authentication) {
      return <Redirect to="/" />
    }

    let softKeys = this.getSoftwareKey(authentication);
    let buttons = this.renderButtons(softKeys);

    return (
      <div id="home">
        <Image className="d-xs-block img-fluid logo-img" src={`/img/logo.png`} alt="Responsive logo"/>

        <Row id='antivirus-form' className="container">
          <Col xs={{ span: 12, offset: 0 }}
            sm={{ spa: 8, offset: 1 }}
            md={{ span: 8, offset: 1 }}
            xl={{ span: 6, offset: 1 }}>

            <hr className="d-xs-block d-sm-none" />

            <h1 className="title">SEU CADASTRO FOI REALIZADO COM SUCESSO!</h1>
            <h3 className="subtitle">PARA OBTER O ANTIVÍRUS CLIQUE NO BOTÃO ABAIXO:</h3>
            {buttons}

            <hr className="d-xs-block d-sm-none" />
          </Col>

          <Col sm={{ span: 3 }}
            md={{ span: 3, offset: 0 }}
            xl={{ span: 4, offset: 0 }}>
            <Image className="d-sm-block img-fluid main-art" src={`/img/main-art.png`} alt="Responsive logo"/>
          </Col>
        </Row>

        <Footer />
      </div>
    )
  }
}

function mapStateToProps({ authReducer }) {
  const { authentication, fetching } = authReducer;

  return { authentication, fetching };
}

export default connect(mapStateToProps)(Antivirus);
