import axios from 'axios';

export function axiosAuth() {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URI,
    headers: { crossdomain: true }
  });

  return instance;
}
