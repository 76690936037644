import { axiosAuth } from '../helpers/axios-auth';
import moment from 'moment';

import {
  CONTRACT,
  FETCHING_CONTRACT,
  ANTIVIRUS_CONTRACT_DENY
} from './types';

export function contractAntivirus(values) {
  let date = moment().format('DD/MM/YYYY');

  return async (dispatch) => {
    try {
      dispatch(fetchingContract());
      const axios = await axiosAuth();
      const response = await axios.post('/antivirus/contract', {
        params: {
          COD_TRANSACAO: "3",
          CD_TIPO_OPERACAO: "2",
          CD_CLIENTE_CORPORATIVO: 147,
          ID_CLIENTE_OPERADOR: values.ID_CLIENTE,
          NR_CONTRATO: 'RIACHUELO.ANTIVIRUS.' + values.NR_CPF, // ID_CLIENTE_OPERADOR
          DT_ADESAO: date,
          DATA_INICIO_CONTRATO: date,
          VL_SERVICO_CONTRATO: 0.0,
          CD_PRODUTO_CLIENTE: '0042', // McAfee Degustação
          DS_PRODUTO_SERVICO: 'Antivirus Riachuelo'
        }
      });
      dispatch(contract(response.data));
    } catch (error) {
      let errorMessage = '';

      if (error.message === 'Network Error') {
        errorMessage = {
          message: 'Verifique a sua conexão e tente novamente.',
          status: null,
        };
      }
      if (error.response && error.response.data) {
        errorMessage = {
          message: error.response.data.MENSAGEM_RETORNO,
          status: error.response.status,
        };
      }
      if (!errorMessage) {
        errorMessage = {
          message: 'Ocorreu um erro. Tente novamente mais tarde.',
          status: 500,
        };
      }
      dispatch(rejectContract(errorMessage))
    }
  }
}

export function contract(data) {
  return {
    type: CONTRACT,
    payload: data,
  }
}

function rejectContract(errorMessage) {
  return {
    type: ANTIVIRUS_CONTRACT_DENY,
    payload: errorMessage,
  }
}

function fetchingContract() {
  return {
    type: FETCHING_CONTRACT,
  }
}
