import React, { Component } from 'react';
import { Image, Row, Col, Container, Button } from 'react-bootstrap';

class Footer extends Component {

  render () {

    return (
      <div className="footer">
        <Image className="d-xs-block img-fluid footer-img" src={`/img/cdf-footer.png`} alt="Responsive logo"/>
      </div>
    )
  }
}

export default Footer;
