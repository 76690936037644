import React, { Component } from 'react';
import { Image, Row, Col, Button } from 'react-bootstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getAntivirusKey, registerAntivirus, contractAntivirus } from '../actions';
import { Form, Field, reduxForm } from 'redux-form';
import FormInput from '../components/FormInput';
import normalization from '../helpers/normalization';
import validate from '../helpers/validation';
import Footer from '../components/Footer';

class Home extends Component {
  state = {
    values: null
  }

  onSubmit = (values) => {
    const { identifier } = values;
    this.setState({ values });
    this.props.getAntivirusKey(identifier.replace(/\D/g, ""));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.authentication && nextProps.authentication) {
      this.props.history.push('/antivirus');
    } else if (!this.props.authError && nextProps.authError && nextProps.authError.status === 401) {
      this.props.registerAntivirus(this.state.values);
    } else if (!this.props.registration && nextProps.registration) {
      this.props.contractAntivirus({ NR_CPF: this.state.values.identifier.replace(/\D/g, ""), ...nextProps.registration });
    } else if (!this.props.contracts && nextProps.contracts) {
      this.props.getAntivirusKey(this.state.values.identifier.replace(/\D/g, ""));
    }
  }

  render () {
    const { handleSubmit, fetchingUser, authError } = this.props;

    return (
      <div id="home">
        <Image className="d-xs-block img-fluid logo-img" src={`/img/logo.png`} alt="Responsive logo"/>

        <Row id='antivirus-form' className="container">
          <Col xs={{ span: 12, offset: 0 }}
            sm={{ spa: 8, offset: 1 }}
            md={{ span: 10, offset: 1 }}
            xl={{ span: 10, offset: 1 }}>

            <hr className="d-xs-block d-sm-none" />

            <h2 className="title">BEM VINDO À PÁGINA DE RESGATE DO SEU ANTIVÍRUS!</h2>
            <h3 className="subtitle">PREENCHA OS DADOS ABAIXO PARA CONCLUIR O CADASTRO:</h3>

            <hr className="d-xs-block d-sm-none" />

          </Col>

          <Col xs={{ span: 12, offset: 0 }}
            sm={{ spa: 8, offset: 1 }}
            md={{ span: 7, offset: 1 }}
            xl={{ span: 4, offset: 1 }}>
            <Form className="form-spacing" onSubmit={handleSubmit(this.onSubmit)}>
              <Field
                name='user'
                label='user'
                placeholder='NOME COMPLETO'
                component={FormInput}
                className='field-required square-input'
                validate={[ validate.notEmpty, validate.wihSpace ]}
              />

              <Field
                name='identifier'
                label='CPF'
                maxLength={14}
                placeholder='CPF'
                className='field-required square-input'
                component={FormInput}
                normalize={normalization.cpf}
                validate={[ validate.notEmpty, validate.cpfLength ]}
                loginError={authError}
                inputMode='numeric'
              />

              <Field
                name='email'
                label='email'
                placeholder='E-MAIL'
                component={FormInput}
                className='field-required square-input'
                validate={[ validate.notEmpty, validate.email ]}
              />

              <Row>
                <Col xs={{ span: 4 }}>
                  <Field
                    name='ddd'
                    label='ddd'
                    maxLength={2}
                    placeholder='DDD'
                    component={FormInput}
                    className='field-required square-input'
                    validate={[ validate.notEmpty ]}
                    inputMode='numeric'
                  />
                </Col>

                <Col xs={{ span: 8 }}>
                  <Field
                    name='fone'
                    label='fone'
                    placeholder='CELULAR'
                    component={FormInput}
                    className='field-required square-input'
                    normalize={normalization.nacionalTel}
                    validate={[ validate.notEmpty ]}
                    inputMode='numeric'
                  />
                </Col>
              </Row>

              <Button type='submit' className='register-button' disabled={fetchingUser}>
                {
                  fetchingUser ?
                    'CADASTRANDO...':
                    'CADASTRAR'
                }
              </Button>
            </Form>
          </Col>

          <Col sm={{ spam: 3 }}
            md={{ span: 3, offset: 0 }}
            xl={{ span: 4, offset: 1 }}>
            <Image className="d-sm-block img-fluid main-art" src={`/img/main-art.png`} alt="Responsive logo"/>
          </Col>
        </Row>

        <Footer />
      </div>
    )
  }
}

function mapStatoToProps({ authReducer, registerReducer, contractReducer }) {
  const { authentication, fetching: fetchAuth, authError: auth } = authReducer;
  const { registration, fetching: fetchRegistration } = registerReducer;
  const { contracts, fetching: fetchContract } = contractReducer;

  let fetchingUser = fetchAuth || fetchRegistration || fetchContract;

  let authError = auth && auth.message === 'Unauthorized' ? {...auth, message: null} : auth;

  return { authentication, registration, contracts, fetchingUser, authError };
}

export default compose(
  connect(mapStatoToProps, { getAntivirusKey, registerAntivirus, contractAntivirus }),
  reduxForm({
    form: 'register',
  })
)(Home);
