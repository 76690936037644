import React, { Component } from 'react';
import Home from './Home';
import Antivirus from './Antivirus';
import { Switch, Route, withRouter } from 'react-router-dom';


class App extends Component {
  render () {
    return (
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/antivirus' component={Antivirus} />
      </Switch>
    )
  }
}

export default withRouter(App);
