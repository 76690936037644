import {
  CONTRACT,
  FETCHING_CONTRACT,
  ANTIVIRUS_CONTRACT_DENY
} from '../actions/types'

const INITIAL_STATE = {
  contracts: null,
  fetching: false,
  contractError: null,
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONTRACT:
      return {
        ...state,
        contracts: action.payload,
        fetching: false,
      }
    case FETCHING_CONTRACT:
      return {
        ...state,
        fetching: true,
        contractError: INITIAL_STATE.contractError,
      }
    case ANTIVIRUS_CONTRACT_DENY:
      return {
        ...state,
        fetching: false,
        contractError: action.payload,
      }
    default:
      return state;
    }
}
