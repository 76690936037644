// Auth
export const ANTIVIRUS_KEY = 'antivirus_key';
export const FETCHING_KEY = 'fetching_key';
export const ANTIVIRUS_KEY_DENY = 'antivirus_key_deny';

export const REGISTRATION = 'REGISTRATION';
export const FETCHING_REGISTRATION = 'fetching_REGISTRATION';
export const ANTIVIRUS_REGISTRATION_DENY = 'antivirus_REGISTRATION_deny';

export const CONTRACT = 'CONTRACT';
export const FETCHING_CONTRACT = 'fetching_contract';
export const ANTIVIRUS_CONTRACT_DENY = 'antivirus_contract_deny';
