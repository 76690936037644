import { axiosAuth } from '../helpers/axios-auth';

import {
  REGISTRATION,
  FETCHING_REGISTRATION,
  ANTIVIRUS_REGISTRATION_DENY
} from './types';

export function registerAntivirus(values) {
  return async (dispatch) => {
    try {
      dispatch(fetchingRegister());
      const axios = await axiosAuth();
      const response = await axios.post('/antivirus/register', {
        params: {
          NR_CPF: values.identifier.replace(/\D/g, ""),
          NM_CLIENTE: values.user,
          LISTA_TELEFONE: [{
            CD_TIPO_TELEFONE: 2,
            NR_DDD: values.ddd,
            NR_FONE: values.fone
          }],
          EMAIL: values.email,

          DS_LOGIN: 'appmobile',
          ID_CLIENTE_CORPORATIVO: 147,
          platform: 'web'
        }
      });
      dispatch(register(response.data));
    } catch (error) {
      let errorMessage = '';

      if (error.message === 'Network Error') {
        errorMessage = {
          message: 'Verifique a sua conexão e tente novamente.',
          status: null,
        };
      }
      if (error.response && error.response.data) {
        errorMessage = {
          message: error.response.data.MENSAGEM_RETORNO,
          status: error.response.status,
        };
      }
      if (!errorMessage) {
        errorMessage = {
          message: 'Ocorreu um erro. Tente novamente mais tarde.',
          status: 500,
        };
      }
      dispatch(rejectRegister(errorMessage))
    }
  }
}

export function register(data) {
  return {
    type: REGISTRATION,
    payload: data,
  }
}

function rejectRegister(errorMessage) {
  return {
    type: ANTIVIRUS_REGISTRATION_DENY,
    payload: errorMessage,
  }
}

function fetchingRegister() {
  return {
    type: FETCHING_REGISTRATION,
  }
}
