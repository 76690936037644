export default {
  cpf: (value) => {
    if (!value) {
      return value;
    }

    let digits = value.replace(/\D/g, "");
    if (digits.length > 14) {
      digits = digits.slice(0, 14);
    }

    return digits
      .replace(/^(\d{3})(\d)/g, "$1.$2")
      .replace(/(\d{3}).(\d{3})(\d)/g, "$1.$2.$3")
      .replace(/(\d{3}).(\d{3}).(\d{3})(\d)/g, "$1.$2.$3-$4");
  },
  tel: (value) => {
    if (!value) {
      return value;
    }

    let digits = value.replace(/\D/g, "");
    if (digits.length > 14) {
      digits = digits.slice(0, 14);
    }

    return digits.replace(/^(\d{2})(\d)/g, "($1) $2").replace(/(\d)(\d{4})$/, "$1-$2");
  },
  nacionalTel: (value) => {
    if (!value) {
      return value;
    }

    let digits = value.replace(/\D/g, "");
    if (digits.length > 9) {
      digits = digits.slice(0, 9);
    }

    return digits.replace(/(\d)(\d{4})$/, "$1-$2"); // replace(/^(\d{2})(\d)/g, "($1) $2")
  },
  number: (value) => {
    if (!value) {
      return value;
    }
    let digits = value.replace(/\D/g, "");
    return digits;
  },
  cep: (value) => {
    if (!value) {
      return value;
    }

    let digits = value.replace(/\D/g, "");
    if (digits.length > 8) {
      digits = digits.slice(0, 8);
    }

    return digits.replace(/^(\d{5})(\d)/g, "$1-$2");
  },
};
